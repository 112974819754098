import { createTheme, Theme } from '@mui/material/styles';

const getCssVariableValue = (variable: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

const getTheme = (darkMode: boolean): Theme => {
  
  const primaryMainColor = darkMode
    ? getCssVariableValue('--boton-800-primario') || '#6CD39B'
    : '#FFFFFF'; // Color principal

  const secondaryMainColor = darkMode
    ? getCssVariableValue('--boton-800-primario') || '#6CD39B'
    : '#3BB071'; // Color secundario con la misma lógica

  return createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: darkMode ? '#121212' : '#FFFFFF',
        paper: darkMode ? '#121212' : '#FFFFFF', // Color de los modales
      },
      text: {
        primary: darkMode ? '#E7EFFC' : '#1E2947',
        secondary: darkMode ? '#A6E5C3' : '#3CB473',
      },
      primary: {
        main: primaryMainColor, // Se utiliza el color definido para primary
      },
      secondary: {
        main: secondaryMainColor, // Se utiliza la misma lógica para secondary
      },
    },
    typography: {
      fontFamily: ['GothamPro', 'Poppins', 'sans-serif'].join(','),
    },
  });
};


export default getTheme;
