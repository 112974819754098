const lightIcons = {
  //ICONOS HOVER
  searchcerrarhover: `${process.env.PUBLIC_URL}/Icon/light/BUSCAR-CERRAR-HOVER_48X48.svg`,
  searchhover: `${process.env.PUBLIC_URL}/Icon/light/BUSCAR-HOVER_48X48.svg`,
  imgUsuarioActivo: `${process.env.PUBLIC_URL}/Icon/light/USUARIO-HOVER_36X36.svg`,
  bolsaHover: `${process.env.PUBLIC_URL}/Icon/light/BOLSA_HOVER_100X100.svg`,
  tiendaHover: `${process.env.PUBLIC_URL}/Icon/light/TIENDA_HOVER_100X100.svg`,

  //ICONOS PASIVO
  searchcerrarpasivo: `${process.env.PUBLIC_URL}/Icon/light/BUSCAR-CERRAR-PASIVO_48X48.svg`,
  searchpasivo: `${process.env.PUBLIC_URL}/Icon/light/BUSCAR-PASIVO_48X48.svg`,
  location: `${process.env.PUBLIC_URL}/Icon/light/LOCATION_PASIVO_20X20.svg`,
  UsuarioPasivo: `${process.env.PUBLIC_URL}/Icon/light/USUARIO-PASIVO_24X24.svg`,
  ClavePasivo: `${process.env.PUBLIC_URL}/Icon/light/CONTRASEÑA-PASIVO_24X24.svg`,

  docPasivo: `${process.env.PUBLIC_URL}/Icon/light/DOC-PASIVO_24X24.svg`,
  rucPasivo: `${process.env.PUBLIC_URL}/Icon/light/RUC-PASIVO_24X24.svg`,
  socialPasivo: `${process.env.PUBLIC_URL}/Icon/light/SOCIAL-PASIVO_24X24.svg`,


  imgUsuarioPasivo: `${process.env.PUBLIC_URL}/Icon/light/USUARIO-PASIVO_36X36.svg`,
  BolsaPasivo: `${process.env.PUBLIC_URL}/Icon/light/BOLSA-PASIVO_24X24.svg`,
  SalirPasivo: `${process.env.PUBLIC_URL}/Icon/light/SALIR-PASIVO_24X24.svg`,

  flechaDerechaPasiva: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_DERECHA_PASIVO.svg`,
  flechaIzquierdaPasiva: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_IZQUIERDA_PASIVA_24X24.svg`,

  imagenIconPasivo: `${process.env.PUBLIC_URL}/Icon/light/IMAGEN_PASIVO_24X24.svg`,
  calendarioPasivo: `${process.env.PUBLIC_URL}/Icon/light/CALENDARIO-PASIVO_24X24.svg`,

  tarjetaPasivo: `${process.env.PUBLIC_URL}/Icon/light/TARJETA-PASIVO_24X24.svg`,

  correoActivo: `${process.env.PUBLIC_URL}/Icon/light/CORREO_ACTIVO_24X24.svg`,

  flechaPasivo: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_ABAJO_PASIVO_24X24.svg`,




  //ICONOS ACTIVO
  UsuarioActivo: `${process.env.PUBLIC_URL}/Icon/light/USUARIO-ACTIVO_24X24.svg`,
  ClaveActivo: `${process.env.PUBLIC_URL}/Icon/light/CONTRASEÑA-ACTIVO_24X24.svg`,
  ActivoVisible: `${process.env.PUBLIC_URL}/Icon/light/CONTRASEÑA-ACTIVO-VER_24X24.svg`,
  ActivoOculto: `${process.env.PUBLIC_URL}/Icon/light/CONTRASEÑA-ACTIVO-OCULTAR_24X24.svg`,

  BolsaActivo: `${process.env.PUBLIC_URL}/Icon/light/BOLSA-ACTIVO_24X24.svg`,
  SalirActivo: `${process.env.PUBLIC_URL}/Icon/light/SALIR-ACTIVO_24X24.svg`,

  EligeActivo: `${process.env.PUBLIC_URL}/Icon/light/ELIGE_ACTIVO_140X140.svg`,
  PagoActivo: `${process.env.PUBLIC_URL}/Icon/light/PAGO_ACTIVO_140X140.svg`,
  RecogeActivo: `${process.env.PUBLIC_URL}/Icon/light/RECOGE_ACTIVO_140X140.svg`,

  cerrarActivo: `${process.env.PUBLIC_URL}/Icon/light/CERRAR-ACTIVO_22X22.svg`,

  imagenIconActivo: `${process.env.PUBLIC_URL}/Icon/light/IMAGEN_ACTIVO_24X24.svg`,

  docActivo: `${process.env.PUBLIC_URL}/Icon/light/DOC-ACTIVO_24X24.svg`,
  rucActivo: `${process.env.PUBLIC_URL}/Icon/light/RUC-ACTIVO_24X24.svg`,
  socialActivo: `${process.env.PUBLIC_URL}/Icon/light/SOCIAL-ACTIVO_24X24.svg`,

  calendarioActivo: `${process.env.PUBLIC_URL}/Icon/light/CALENDARIO-ACTIVO_24X24.svg`,

  tarjetaActivo: `${process.env.PUBLIC_URL}/Icon/light/TARJETA-ACTIVO_24X24.svg`,

  ccvvActivo: `${process.env.PUBLIC_URL}/Icon/light/CCVV_ACTIVO_24X24.svg`,
  ccvvPasivo: `${process.env.PUBLIC_URL}/Icon/light/CCVV_PASIVO_24X24.svg`,

  correoPasivo: `${process.env.PUBLIC_URL}/Icon/light/CORREO_PASIVO_24X24.svg`,
  
  flechaActivo: `${process.env.PUBLIC_URL}/Icon/light/FLECHA_ABAJO_ACTIVO_24X24.svg`,

  //ICONOS
  menos: `${process.env.PUBLIC_URL}/Icon/light/MENOS_CARRITO_24X24.svg`,
  masHover: `${process.env.PUBLIC_URL}/Icon/light/MAS_HOVER_40X40.svg`,
  masPasivo: `${process.env.PUBLIC_URL}/Icon/light/MAS_PASIVO_40X40.svg`,
  checkActivo: `${process.env.PUBLIC_URL}/Icon/light/CHECK_ACTIVO_40X40.svg`,
  mas: `${process.env.PUBLIC_URL}/Icon/light/MAS_CARITO_24X24.svg`,
  cerrar: `${process.env.PUBLIC_URL}/Icon/light/CERRAR_GREEN_ACTIVO_24x24.svg`,
  mesa: `${process.env.PUBLIC_URL}/Icon/light/ICONO_MESA_44x28.svg`,
};

const darkIcons = {
  //ICONOS HOVER
  searchcerrarhover: `${process.env.PUBLIC_URL}/Icon/Dark/BUSCAR-CERRAR-HOVER_48X48.svg`,
  searchhover: `${process.env.PUBLIC_URL}/Icon/Dark/BUSCAR-HOVER_48X48.svg`,
  imgUsuarioActivo: `${process.env.PUBLIC_URL}/Icon/Dark/USUARIO-HOVER_36X36.svg`,
  bolsaHover: `${process.env.PUBLIC_URL}/Icon/Dark/BOLSA_HOVER_100X100.svg`,
  tiendaHover: `${process.env.PUBLIC_URL}/Icon/Dark/TIENDA_HOVER_100X100.svg`,

  //ICONOS PASIVO
  searchcerrarpasivo: `${process.env.PUBLIC_URL}/Icon/Dark/BUSCAR-CERRAR-PASIVO_48X48.svg`,
  searchpasivo: `${process.env.PUBLIC_URL}/Icon/Dark/BUSCAR-PASIVO_48X48.svg`,
  location: `${process.env.PUBLIC_URL}/Icon/Dark/LOCATION_PASIVO_20X20.svg`,
  UsuarioPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/USUARIO-PASIVO_24X24.svg`,
  ClavePasivo: `${process.env.PUBLIC_URL}/Icon/Dark/CONTRASEÑA-PASIVO_24X24.svg`,
  imgUsuarioPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/USUARIO-PASIVO_36X36.svg`,
  BolsaPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/BOLSA-PASIVO_24X24.svg`,
  SalirPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/SALIR-PASIVO_24X24.svg`,

  flechaDerechaPasiva: `${process.env.PUBLIC_URL}/Icon/Dark/FLECHA_DERECHA_PASIVO.svg`,

  imagenIconPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/IMAGEN_PASIVO_24X24.svg`,


  docPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/DOC-PASIVO_24X24.svg`,
  rucPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/RUC-PASIVO_24X24.svg`,
  socialPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/SOCIAL-PASIVO_24X24.svg`,


  docActivo: `${process.env.PUBLIC_URL}/Icon/Dark/DOC-ACTIVO_24X24.svg`,
  rucActivo: `${process.env.PUBLIC_URL}/Icon/Dark/RUC-ACTIVO_24X24.svg`,
  socialActivo: `${process.env.PUBLIC_URL}/Icon/Dark/SOCIAL-ACTIVO_24X24.svg`,


  //ICONOS
  menos: `${process.env.PUBLIC_URL}/Icon/Dark/MENOS_CARRITO_24X24.svg`,
  masHover: `${process.env.PUBLIC_URL}/Icon/Dark/MAS_HOVER_40X40.svg`,
  masPasivo: `${process.env.PUBLIC_URL}/Icon/Dark/MAS_PASIVO_40X40.svg`,
  checkActivo: `${process.env.PUBLIC_URL}/Icon/Dark/CHECK_ACTIVO_40X40.svg`,
  mas: `${process.env.PUBLIC_URL}/Icon/Dark/MAS_CARITO_24X24.svg`,



  //ICONOS ACTIVO
  UsuarioActivo: `${process.env.PUBLIC_URL}/Icon/Dark/USUARIO-ACTIVO_24X24.svg`,
  ClaveActivo: `${process.env.PUBLIC_URL}/Icon/Dark/CONTRASEÑA-ACTIVO_24X24.svg`,
  ActivoVisible: `${process.env.PUBLIC_URL}/Icon/Dark/CONTRASEÑA-ACTIVO-VER_24X24.svg`,
  ActivoOculto: `${process.env.PUBLIC_URL}/Icon/Dark/CONTRASEÑA-ACTIVO-OCULTAR_24X24.svg`,
  BolsaActivo: `${process.env.PUBLIC_URL}/Icon/Dark/BOLSA-ACTIVO_24X24.svg`,
  SalirActivo: `${process.env.PUBLIC_URL}/Icon/Dark/SALIR-ACTIVO_24X24.svg`,

  EligeActivo: `${process.env.PUBLIC_URL}/Icon/Dark/ELIGE_ACTIVO_140X140.svg`,
  PagoActivo: `${process.env.PUBLIC_URL}/Icon/Dark/PAGO_ACTIVO_140X140.svg`,
  RecogeActivo: `${process.env.PUBLIC_URL}/Icon/Dark/RECOGE_ACTIVO_140X140.svg`,
  flechaIzquierdaPasiva: `${process.env.PUBLIC_URL}/Icon/Dark/FLECHA_IZQUIERDA_PASIVA_24X24.svg`,
  cerrarActivo: `${process.env.PUBLIC_URL}/Icon/Dark/CERRAR-ACTIVO_22X22.svg`,

  imagenIconActivo: `${process.env.PUBLIC_URL}/Icon/Dark/IMAGEN_ACTIVO_24X24.svg`,
};

const LogoIcons = {
  imgCajaWeb: `${process.env.PUBLIC_URL}/Icon/logo/LOGO-CAJA-WEB_180X180.svg`,
  imgDinersClub: `${process.env.PUBLIC_URL}/Icon/logo/DINERS_CLUB_48X48.svg`,
  imgAmericaExpress: `${process.env.PUBLIC_URL}/Icon/logo/AMERICAN_EXPRESS_48X48.svg`,
  imgMastercad: `${process.env.PUBLIC_URL}/Icon/logo/MASTERCARD_48X48.svg`,
  imgVisa: `${process.env.PUBLIC_URL}/Icon/logo/VISA_48X48.svg`,
  imgPoweredBy: `${process.env.PUBLIC_URL}/Icon/logo/POWERD-BY-132X28.svg`,
};

const ImagenGif = {
  smileFace: `${process.env.PUBLIC_URL}/Icon/GIF/SMILE_IMAGEN.gif`,
  PinPos: `${process.env.PUBLIC_URL}/Icon/GIF/PINPAD_POS.gif`,
}

const lightColors = {
  backgrounmenu: "var(--color-opacidades-primario-92, rgba(66, 198, 126, 0.92))",
  hoverColorPedido: "var(--color-full-800-secundario, #79DD8B)",
  checkbackgroun: "var(--color-full-800-primario, #42C67E)",
  searchbackgroun: "#FFFFFF",
  activo: "#42C67E",
  pasivo: "#30ADD2",
};

const darkColors = {
  backgrounmenu: "var(--color-opacidades-primario-92, rgba(66, 198, 126, 0.92))",
  hoverColorPedido: "var(--color-full-800-secundario, #79DD8B)",
  checkbackgroun: "var(--color-full-800-primario, #42C67E)",
  searchbackgroun: "#FFFFFF",
  activo: "#42C67E",
  pasivo: "#30ADD2",

};

export const themes = {
  light: {
    icons: lightIcons,
    colors: lightColors,
    logo: LogoIcons,
    Gif: ImagenGif
  },
  dark: {
    icons: darkIcons,
    colors: darkColors,
    logo: LogoIcons,
    Gif: ImagenGif
  },
};
