import React, { lazy, Suspense, useEffect, useMemo, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoaderScreen from "../pages/LoaderScreen";
import ProtectedRoute from "../authenticate/protectedRoutes";
import PublicRoute from "../authenticate/publicRoutes";
import { ThemeProvider, CssBaseline } from "@mui/material";
import getTheme from "../config/theme";

const CartScreen = lazy(() => import("../pages/CartScreen"))
const CheckoutScreen = lazy(() => import('../pages/CheckoutScreen/CheckoutScreen'))
const MainScreen = lazy(() => import('../pages/MainScreen/MainScreen'))
const LandingScreen = lazy(() => import('../pages/LandingScreen'))

const Index = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);
  
  //SOLO ACTIVAR EL MODO DARK SI ES NECESARIO
  const handleThemeChange = () => {
    setDarkMode((prevMode) => !prevMode);
  };
  
  useEffect(() => {
    const url = window.location.href;
    const parts = url.split('/'); 
    
    if(parts[parts.length - 1] === "1"){
      const newUrl = parts.slice(0, -1).join('/');
      window.location.href = newUrl;    
    }    
   
  }, []); 
  
  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            {/* Public rutes */}
            {/* <Route path="status/404" element={<Status404 />} />           
              <Route path="*" element={<Status404 />} /> */}
            <Route path="/" element={<LandingScreen />} />  
            {/* ROUTE DEL MAIN (PUBLICAS) */}
            <Route path="/:idStoreRoute/:idMesaRoute" element={<MainScreen />} />
            {/* ROUTE DEL MAIN (PRIVADAS) */}        
            <Route path="/listapedido/:idStoreRoute/:idMesaRoute" element={<CartScreen />} />          
            <Route path="/checkout/:idStoreRoute/:idMesaRoute" element={<CheckoutScreen />} />          
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  )
}

export default Index;
